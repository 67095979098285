import React from "react"
import Layout from "../../components/layout/Layout"
import CardArticle from "../../components/card/CardArticle"
import Section from "../../components/section/Section"
import css from "./Blog.module.css"

export default ({ pageContext: { url, data } }) => {
  return (
    <Layout
      type="HMF"
      className={css.blog}
      slug={url}
      title="Blog"
      theme="blog"
    >
      <Section className={css.grids}>
        <h1>Blog</h1>
        <div className={css.articles}>
          {data.articles.map((item, i) => (
            <CardArticle
              key={i}
              url={`/${item.slug}`}
              cover={item.portada.file.url}
              date={item.fecha}
              title={item.titulo}
            />
          ))}
        </div>
      </Section>
    </Layout>
  )
}
